<template>
  <div>
    <h2 class="col-lg-12 text-center mb-3">{{ ('Edit Speaker') }}</h2>
    <FormSpeakers :baseSpeaker="speaker" @submit-requested="submitRequested"></FormSpeakers>
  </div>
</template>
 
<script>
import { mapState, mapActions } from 'vuex'
import FormSpeakers from '../forms/FormSpeakers.vue'
import router from '@/router';

  
  export default {  
    data () {
    return {
      speaker: null
    }
    
  },
  components: { FormSpeakers },
  computed:{
    ...mapState('speakers', ['speakerOutput']),
  },
  methods: {
    ...mapActions('speakers', ['editSpeakers']),
    ...mapActions('speakers', ['getList']),
    submitRequested (speaker) {
      this.editSpeakers(speaker);
    },
  },
  mounted: function()
  {
    if(!this.$route.params.speaker)
    {
      router.push({ path: '/speakers/list' });
    }
    else
    {
      this.speaker = this.$route.params.speaker;
    }
  }
}
</script> 
 
<style scoped>
</style> 